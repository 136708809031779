import React from "react"
import SEO from "../components/SEO"
import Layout from "../components/layout"
import Section from "../components/section"
import Items from "../components/items"
import ItemBox from "../components/itembox"

export default () => <>
    <SEO title="Research" pathname="/research/" />
    <Layout title="Research">
        <Section sectionTitle="Papers">
            <Items>
                <ItemBox
                    title="Generalised second law beyond the semiclassical regime"
                    links={<><a href="http://arxiv.org/abs/2412.01903">arXiv</a>, <a href="http://arxiv.org/pdf/2412.01903">PDF</a></>}
                    blurb="Josh Kirklin, December 2024"
                />
                <ItemBox
                    title="Linearization (in)stabilities and crossed products."
                    links={<><a href="http://arxiv.org/abs/2411.19931">arXiv</a>, <a href="http://arxiv.org/pdf/2411.19931">PDF</a></>}
                    blurb="Julian De Vuyst, Stefan Eccles, Philipp A. H&#246;hn, Josh Kirklin, November 2024"
                />
                <ItemBox
                    title="Quantum Gravity, Hydrodynamics and Emergent Cosmology: A Collection of Perspectives."
                    links={<><a href="http://arxiv.org/abs/2411.12628">arXiv</a>, <a href="http://arxiv.org/pdf/2411.12628">PDF</a></>}
                    blurb="Josh Kirklin, et al., November 2024"
                />
                <ItemBox
                    title="Gravitational entropy is observer-dependent."
                    links={<><a href="http://arxiv.org/abs/2405.00114">arXiv</a>, <a href="http://arxiv.org/pdf/2405.00114">PDF</a></>}
                    blurb="Julian De Vuyst, Stefan Eccles, Philipp A. H&#246;hn, Josh Kirklin, April 2024"
                />
                <ItemBox
                    title="Probes, purviews, purgatories, parable, paradox?"
                    links={<><a href="http://arxiv.org/abs/2304.00679">arXiv</a>, <a href="http://arxiv.org/pdf/2304.00679">PDF</a></>}
                    blurb="Josh Kirklin, April 2023"
                />
                <ItemBox
                    title="Emergent classical gauge symmetry from quantum entanglement."
                    links={<><a href="http://arxiv.org/abs/2209.03979">arXiv</a>, <a href="http://arxiv.org/pdf/2209.03979">PDF</a></>}
                    blurb="Josh Kirklin, September 2022"
                />
                <ItemBox
                    title="Diffeomorphism-invariant observables and dynamical frames in gravity: reconciling bulk locality with general covariance."
                    links={<><a href="http://arxiv.org/abs/2206.01193">arXiv</a>, <a href="http://arxiv.org/pdf/2206.01193">PDF</a></>}
                    blurb="Christophe Goeller, Philipp A. H&#246;hn, Josh Kirklin, June 2022"
                />
                <ItemBox
                    title="Islands and Uhlmann phase: Explicit recovery of classical information from evaporating black holes."
                    links={<><a href="http://arxiv.org/abs/2011.07086">arXiv</a>, <a href="https://doi.org/10.1007/JHEP01(2022)119">DOI</a>, <a href="http://arxiv.org/pdf/2011.07086">PDF</a></>}
                    blurb={<>Josh Kirklin, November 2020<br />J. High Energ. Phys. (2022) 2022: 119</>}
                />
                <ItemBox
                    title="Holography from Decoherence and Entanglement."
                    links={<><a href="http://arxiv.org/abs/2006.13246">arXiv</a>, <a href="http://arxiv.org/pdf/2006.13246">PDF</a></>}
                    blurb="Josh Kirklin, June 2020"
                />
                <ItemBox
                    title="The Holographic Dual of the Entanglement Wedge."
                    links={<><a href="http://arxiv.org/abs/1910.00457">arXiv</a>, <a href="https://doi.org/10.1007/JHEP01(2020)071">DOI</a>, <a href="http://arxiv.org/pdf/1910.00457">PDF</a></>}
                    blurb={<>Josh Kirklin, Oct 2019<br />J. High Energ. Phys. (2020) 2020: 071</>}
                />
                <ItemBox
                    title="Unambiguous Phase Spaces for Subregions."
                    links={<><a href="http://arxiv.org/abs/1901.09857">arXiv</a>, <a href="https://doi.org/10.1007/JHEP03(2019)116">DOI</a>, <a href="http://arxiv.org/pdf/1901.09857">PDF</a></>}
                    blurb={<>Josh Kirklin, Jan 2019<br />J. High Energ. Phys. (2019) 2019: 116</>}
                />
                <ItemBox
                    title="Subregions, Minimal Surfaces and Entropy in Semiclassical Gravity."
                    links={<><a href="http://arxiv.org/abs/1805.12145">arXiv</a>, <a href="http://arxiv.org/pdf/1805.12145">PDF</a></>}
                    blurb="Josh Kirklin, May 2018"
                />
                <ItemBox
                    title="Localisation of Soft Charges, and Thermodynamics of Softly Hairy Black Holes."
                    links={<><a href="http://arxiv.org/abs/1802.08145">arXiv</a>, <a href="https://doi.org/10.1088/1361-6382/aad204">DOI</a>, <a href="http://arxiv.org/pdf/1802.08145">PDF</a></>}
                    blurb={<>Josh Kirklin, Feb 2018<br />Class. Quantum Grav. 35 (2018) 175010</>}
                />
            </Items>
        </Section>
        <hr />
        <Section sectionTitle="Seminars">
            <Items>
                <ItemBox
                    title="Gravitational entropy: a tale of five approximations."
                    links={<><a href="/seminars/oistexu/slides.pdf">Slides</a>, <a href="https://vimeo.com/1022334413/d1c1113e0c">Video</a></>}
                    blurb="Quantum Extreme Universe (OIST), 2024"
                />
                <ItemBox
                    title="Fighting non-locality with non-locality."
                    links={<><a href="/seminars/fightingnonlocality/slides.pdf">Slides</a>, <a href="https://pirsa.org/24090130">Video</a></>}
                    blurb="OIST, Causalworlds (Perimeter), 2024"
                />
                <ItemBox
                    title="Gravitational entropy is observer-dependent."
                    links={<><a href="/seminars/gravitationalentropyobserverdependent/slides.pdf">Slides</a></>}
                    blurb="UNB, 2024"
                />
                <ItemBox
                    title="Emergent classical gauge symmetry from quantum entanglement."
                    links={<><a href="/seminars/emergentgaugesymmetry/slides.pdf">Slides</a>, <a href="https://pirsa.org/22120023">Video</a></>}
                    blurb="Hokudai, Perimeter, 2023"
                />
                <ItemBox
                    title="Uhlmann phase, black hole information and holography."
                    links={<><a href="/seminars/uhlmannphaseblackholeinformation/notes.pdf">Notes</a>, <a href="/seminars/uhlmannphaseblackholeinformation/slides.pdf">Slides</a>, <a href="https://vimeo.com/518849258">Video</a></>}
                    blurb="QASTM, IPM Tehran, UCL, OIST, AEI Potsdam, 2021"
                />
                <ItemBox
                    title="Holographic Uhlmann holonomy and the entanglement wedge symplectic form."
                    links={<><a href="/seminars/holographicuhlmannholonomy/notes.pdf">Notes</a>, <a href="/seminars/holographicuhlmannholonomy/slides.pdf">Slides</a>, <a href="https://pirsa.org/19120055">Video</a></>}
                    blurb="CERN, Perimeter, Southampton, UCL, Cambridge, QMUL, 2019-2020"
                />
                <ItemBox
                    title="Minimal surfaces in semiclassical gravity."
                    links={<><a href="/seminars/subregions/notes.pdf">Notes</a>, <a href="/seminars/subregions/slides.pdf">Slides</a></>}
                    blurb="Cambridge, 2018"
                />
            </Items>
        </Section>
    </Layout>
</>
